// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .scope-of-work-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-item {
  margin-bottom: 20px;
}

.radio-options {
  margin: 10px 0;
}

.radio-options label {
  margin-right: 10px;
} */

.scrollable-table {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important; /* For smooth scrolling on mobile devices */
  /* background-color: red; */
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/AddProject.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;GAgBG;;AAEH;EACE,2BAA2B;EAC3B,4CAA4C,EAAE,2CAA2C;EACzF,2BAA2B;AAC7B","sourcesContent":["/* .scope-of-work-form {\n  max-width: 600px;\n  margin: 0 auto;\n  padding: 20px;\n}\n\n.form-item {\n  margin-bottom: 20px;\n}\n\n.radio-options {\n  margin: 10px 0;\n}\n\n.radio-options label {\n  margin-right: 10px;\n} */\n\n.scrollable-table {\n  overflow-x: auto !important;\n  -webkit-overflow-scrolling: touch !important; /* For smooth scrolling on mobile devices */\n  /* background-color: red; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
